import React from "react"
import { graphql } from "gatsby"
import SiteLayout from "../../../components/sitelayout"
import Container from "../../../components/container"
import Header from "../../../components/header"
import SEO from "../../../components/seo"
import {SegmentSubTitle, SegmentText } from "../../../components/segment"
import Screenshot from "../../../components/screenshot"
import FlexColumnWrapper from "../../../components/flexcolumnwrapper"
import MenuItem from "../../../components/menuitem"
import SupportCta from "../../../components/supportcta"
import SubText from "../../../components/subtext"

import { useTheme } from "../../../services/theme"


const HubspotRemoveFaq = ({data}) => {
    

    const theme = useTheme()
    return (
        <SiteLayout
            menu={
                <MenuItem to="/help">Help Center</MenuItem>
            }
        >
            <SEO title="FAQ - How do I remove a Hubspot form?"/>
            <Container>
                <Header
                    paddingTop="10vh"
                    paddingBottom="10vh"
                    category="FAQ"
                    maxWidth="960px"
                    title="How do I remove a Hubspot form?"
                />
                <FlexColumnWrapper 
                    marginBottom="5%"
                    maxWidth="480px"
                    marginLeft="auto"
                    marginRight="auto"
                    breakpoint={theme.breakpoints.mobile}
                    responsiveStyle="padding-left: 20px; padding-right: 20px;"
                >
                    <SegmentSubTitle>Step 1: Select the form</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="10px">
                        &#8594;Click 'Websites' and choose the website where you are using the form.<br/><br/>
                        &#8594;Choose the form that you want to remove.<br/><br/>
                    </SegmentText>
                    <SegmentSubTitle id="customattribute">Step 2: Remove the form</SegmentSubTitle>
                    <SegmentText marginTop="0px" marginBottom="20px">                        
                        &#8594;Type the website domain and click remove form.
                        <SubText align="left">Once you remove the form on Vimkit, your Webflow form will no longer send submissions to Hubspot.</SubText><br/>
                    </SegmentText>
                    <Screenshot fluid={data.deleteFormScreenshot.childImageSharp.fluid} minWidth="340px" maxWidth="480px" marginBottom="40px"/>
                    <SegmentSubTitle>Step 3: Finally, remove any custom attributes from the form on Webflow.</SegmentSubTitle>
                </FlexColumnWrapper>
            </Container>
            <SupportCta/>
            
        </SiteLayout>    
    )
}

export default HubspotRemoveFaq

export const query = graphql`
    query {
        deleteFormScreenshot: file(relativePath: { eq: "screenshots/deleteform.png" }) {
            childImageSharp {
                fluid(maxWidth: 480, quality: 100) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }  
            }
        }
    }
`